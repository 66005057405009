<template>
  <v-card elevation="0" class="ma-5"
    >There are no {{ type }} related to this customer</v-card
  >
</template>
<script>
export default {
  name: "EmptyTableSlot",
  props: ["type"],
  data() {
    return {};
  },
};
</script>